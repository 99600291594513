//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import User from '~/components/User.vue';
import constant from '~/const/index';
import Filters from './TableFilters.vue';
import Extra from './Extra.vue';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: {
    Filters,
    Extra,
    Modal,
    User,
  },

  props: {
    merchantId: { type: Number, default: 0 },
  },

  data() {
    return {
      editStore: {
        show: false,
        data: null,
        isCreating: false,
      },
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  // SINGLE TABLE COMPONENT
  // async mounted() {
  //   const { page } = this.$route.query;
  //   const numberPage = Number(page);
  //   if (numberPage) {
  //     this.setPageAction(numberPage);
  //   }

  //   this.setGeneralLoader(true);
  //   await this.loadData();
  //   this.setGeneralLoader(false);
  // },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('merchantsStores', ['page', 'pages', 'data', 'sort']),

    merchantIds() {
      const { merchantId } = this;
      if (merchantId) return [merchantId];
      return undefined;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('merchantsStores', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editStoreAction: 'editStore',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction({ ...data, merchantIds: this.merchantIds });
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      // SINGLE TABLE COMPONENT
      // const query = { page: value };
      // this.$router.replace({ query });

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    edit(row) {
      this.editStore.isCreating = false;
      this.editStore.show = true;
      this.editStore.data = row;
    },

    applyEdit({ input }) {
      this.confirmAction({
        text: 'Are you sure you want to edit store?',
        callback: async () => {
          this.setGeneralProgress(true);
          try {
            await this.editStoreAction(input);
            await this.loadDataAction({ merchantIds: this.merchantIds });
            this.editStore.show = false;
            this.setSuccessNotification('Store edited');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
        },
      });
    },
  },
};
