var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px","show-extra":""},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('Extra',{attrs:{"data":row}})]}},{key:"username",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":row.merchantId,"username":row.merchantName}})]}},{key:"storename",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.name)+" ")]}},{key:"webSiteUrl",fn:function(ref){
var row = ref.row;
return [_c('a',{attrs:{"href":row.webSiteUrl,"target":"_blank"}},[_vm._v(" "+_vm._s(row.webSiteUrl)+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:{
            'success--text': row.status === _vm.constant.merchants.STORE_STATUS.ACTIVE,
            'warning--text': row.status === _vm.constant.merchants.STORE_STATUS.AWAITING_APPROVAL,
            'error--text': row.status === _vm.constant.merchants.STORE_STATUS.REJECTED,
            'blue-grey--text text--lighten-3': row.status === _vm.constant.merchants.STORE_STATUS.INACTIVE,
          }},[_vm._v(" "+_vm._s(_vm.$utils.getCaption(_vm.constant.merchants.STORE_STATUS_LIST, row.status))+" ")])]}},{key:"quantity",fn:function(ref){
          var row = ref.row;
return [_vm._v(" "+_vm._s(row.productsQuantity)+" ")]}},{key:"created",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.created * 1000))+" "),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.$utils.getTime(row.created * 1000)))])]),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(row)}}},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-pencil ")])],1)],1)])]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('Modal',{attrs:{"data":_vm.editStore.data,"is-creating":_vm.editStore.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editStore.show),callback:function ($$v) {_vm.$set(_vm.editStore, "show", $$v)},expression:"editStore.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }